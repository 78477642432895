import React from 'react-dom'
import './about.css'

function About() {
  return (
    <div className='about'>
      <div>
        <div className='bio-item'>
          <img className="about-img" src="assets/bioimage.jpeg"/>
          <div className='about-text'>
            <h1>Star and Bumblebee Books</h1>
            <p>
              Star and Bumblebee Books is a new venture by budding children’s author, Jacqueline Paul, and her daughter, illustrator and artist, Ȼosiniye Paul.
            </p>
            <p>
              The two have banded together to bring to life stories of growing up with family pets.
            </p>
            <p>
              Jacqueline recounts many adventures of acquiring animals while raising her children, homeschooling, and working as an acupuncturist.
            </p>
            <p>
              In uplifting, rhyming prose she recalls the desires of her kids to add yet another animal to the scraggle-muffin menagerie on their property.
            </p>
            <p>
              As a first-time illustrator Ȼosiniye, (pronounced: Kwa-see-knee-ah) brings whimsical, fun, adventurous, and loveable characters to the pages of her Mum’s books.
            </p>
            <p>
              Look out for more books from The Pets of the Family in the Field, Tales of Takaroni, and The Adventures of Red Reidy Series.
            </p>
          </div>
        </div>
        <hr className='about-break'/>
        <div className='bio-item'>
          <img className="about-img" src="assets/jbioimage.jpeg"/>
          <div className='about-text'>
            <h1> Jacqueline Paul</h1>
            <p>
              Jacqueline Paul is an acupuncturist, a mother, and a lover of life.
            </p>
            <p>
              Seasonal decorator, cheerful organizer, intermediate rock climber and exhibitor of a gleeful spirit she enjoys putting into words the observations of the little things around her.
            </p>
            <p>
              Born in New Zealand in the 70’s, raised on Vancouver Island, she happily travels between her two homes.
            </p>
            <p>
              Never far from the beach and often basking in the sun, smelling flowers and picking up pretty stones, comes naturally.
            </p>
            <p>
              She writes most of her work, sitting on her comfy grey couch, gazing out the window, watching the hummingbirds.
            </p>
          </div>
        </div>
        <hr className='about-break'/>
        <div className='bio-item'>
          <img className="about-img" src="assets/sinaimg.jpg"/>
          <div className='about-text'>
            <h1> Ȼosiniye Paul </h1>
            <p>
              Coast Salish artist Ȼosiniye Paul, born in 2000, is the daughter of artist Chris Paul.
              <p>
              </p>
              Raised on Tsartlip territory, their Coast Salish name Ȼosiniye, meaning star woman, was given to them by their aunt Linda Elliott.
              <p>
              </p>
              Like the name, their work often features the night sky alongside whimsical woodland creatures.
              <p>
              </p>
              Besides print making they also work with water colour and create wood jewelry and sandblasted pieces.
              <p>
              </p>
              Their work has been exhibited at Mark Loria Gallery (formerly Alcheringa), UVic Legacy Art Galleries, and the Art Gallery of Greater Victoria.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
