import React, { Fragment, useState, Component }from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function NavbarBurger(props) {
  let [burgerClass, setBurgerClass ] = useState('navbar-burger navbar-burger-')
  let [dropdownStyle, setDropdownStyle] = useState({display: 'none'})
  let [burgerClicked, setBurgerClicked] = useState(false)

  let handleBurgerClick = () => {
    if(burgerClicked === false) {
      setBurgerClass('navbar-burger navbar-burger-selected-')
      setDropdownStyle({display: 'flex'})
      setBurgerClicked(true)
    } else {
      setBurgerClass('navbar-burger navbar-burger-')
      setDropdownStyle({display: 'none'})
      setBurgerClicked(false)
    }
  }

  return (
    <header className={props.class_name_prop}>
      <nav className="navbar-content">
        <Link className='navbar-logo' to='/'>
          <img className='' src='assets/logo.png'/>
        </Link>
        <span className="navbar-list">
          <span className={burgerClass + props.colour} onClick={() => handleBurgerClick()}><FontAwesomeIcon icon={faBars} /></span>
        </span>
      </nav>
      <div className='dropdown-content' style={dropdownStyle}> {props.makeNavList(props)} </div>
    </header>
  )
}

export default NavbarBurger
