import './buy-links.css'

function Buy_links() {
    let buy_items = [
        {
            img: 'assets/images/strongnations.logo.png',
            link: 'https://www.strongnations.com/store/11487/nuhquim-the-little-red-puppy-a-star-and-bumblebee-book',
        },
        {
            img: 'store_imgs/Barnes-and-Noble-USA-768x768.png',
            link: 'https://www.barnesandnoble.com/w/nuhquim-the-little-red-puppy-jacqueline-paul/1141969899?ean=9780228870616'
        },
        {
            img: 'store_imgs/Indigo-Canada-768x768.png',
            link: 'https://www.chapters.indigo.ca/en-ca/books/nuhquim-the-little-red-puppy/9780228870616-item.html?ikwid=NuhQuim&ikwsec=Home&ikwidx=1#algoliaQueryId=fb37b972740e6a5637ae0a700ac28843'
        },
        {
            img: 'store_imgs/BD-768x768.png',
            link: 'https://www.bookdepository.com/Nuhquim-Little-Red-Puppy-Jacqueline-Paul/9780228870616?ref=grid-view&qid=1663808351714&sr=1-1'
        },
        {
            img: 'store_imgs/abe_books.png',
            link: 'https://www.abebooks.com/servlet/BookDetailsPL?bi=31268723063&searchurl=ds%3D20%26kn%3DNuhQuim%26sortby%3D17&cm_sp=snippet-_-srp1-_-title2'
        },
        {
            img: 'store_imgs/walmart-logo3-24.jpg',
            link: 'https://www.walmart.com/ip/Nuhquim-The-Little-Red-Puppy-A-Star-and-Bumblebee-Book-Other-9780228870616/1481442719?wmlspartner=wlpa&selectedSellerId=101114306'
        },
        {
            img: 'store_imgs/Amazon-Purchase-Book-768x768.png',
            link: 'https://www.amazon.com/Nuhquim-Little-Red-Puppy-Bumblebee-ebook/dp/B0B7ZZR3LJ/ref=mp_s_a_1_1?crid=2U7A6C3FGC6S8&keywords=nuhquim%2C+the+little+red+puppy&qid=1660939066&sprefix=nuhquim%2C+the+little+red+puppy%2Caps%2C189&sr=8-1'
        },
        {
            img: 'store_imgs/Amazon-Canada-Purchase-Book-768x768.png',
            link: 'https://www.amazon.ca/Nuhquim-Little-Red-Puppy-Bumblebee-ebook/dp/B0B7ZZR3LJ/ref=mp_s_a_1_1?crid=2U7A6C3FGC6S8&keywords=nuhquim%2C+the+little+red+puppy&qid=1660939066&sprefix=nuhquim%2C+the+little+red+puppy%2Caps%2C189&sr=8-1'
        },
    ]

    let buy_local = [
        {
            img: 'assets/images/books_and_shenanigans.png',
            link: 'https://www.booksandshenanigans.com/item/-99iwT0AEESzXmeoyyh7sg'
        },
        {
            img: 'assets/images/munros-books.png',
            link: 'https://www.munrobooks.com/item/-99iwT0AEESr-Pq_K6F6SA'
        },
        {
            img: 'assets/images/tanners-books.png',
            link: 'https://www.tannersbooks.com/item/-99iwT0AEESzXmeoyyh7sg'
        },
    ]


    return (
        <div>
            <div className="buy-links-container">
                {buy_items.map(item => {
                    return (
                        <a className="buy-links-ancher" key={item.link} href={item.link}>
                            <img className="buy-links-img" src={item.img} />
                        </a>
                    )
                })}
            </div>
            <h2 className='buy-links-label'>Buy locally:</h2>
            <div className="buy-links-container">
                {buy_local.map(item => {
                    return (
                        <a className="buy-links-ancher" key={item.link} href={item.link}>
                            <img className="buy-links-img" src={item.img} />
                        </a>
                    )
                })}
            </div>
        </div>
    )
}

export default Buy_links
