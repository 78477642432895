import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import './educational.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import 'font-awesome/css/font-awesome.min.css';

import Contacts from './Contacts.jsx'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function paginate(numPages, currentPage, setPageNumber) {
  let i = 0
  let buttons = []
  let startOffset = 1
  let endOffset = 1

  if(currentPage === 1)
    startOffset = 0

  if(currentPage === numPages)
    endOffset = 0

  buttons.push(<button onClick={() => setPageNumber(currentPage-startOffset)} key='left' className='pagination-start pagination-button'>
    <FontAwesomeIcon size='sm' className='pagination-arrow' icon={faArrowLeft} />
  </button>)

  for(; i < numPages; i++) {
    let selectedName = ''
    if(i+1 === currentPage)
      selectedName = 'pagination-selected'
    buttons.push(<button value={i+1} onClick={(e) => setPageNumber(parseInt(e.target.value))} key={i} className={'pagination-button ' + selectedName}>
      {i+1}
    </button>)
  }

  buttons.push(<button onClick={() => setPageNumber(currentPage+endOffset)} key='right' className='pagination-end pagination-button'>
    <FontAwesomeIcon size='sm' className='pagination-arrow' icon={faArrowRight} />
  </button>)

  return (<div className='pagination-container'>{buttons}</div>)
}

export default function Educational() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  let setCurrentPageNumber = (num) => {
    setPageNumber(num)
  }

  let width = window.innerWidth;

  return (
    <div className='educational-page'>
      <h1>
        Educational Resources
      </h1>
      <div className='pdf-container'>
        <a download="Jacqueline_Paul_Teacher_Resource.pdf" href='Jacqueline_Paul_Teacher_Resource.pdf'>
          Download Here!
        </a>
        <a href='Jacqueline_Paul_Teacher_Resource.pdf'>
          <Document
            className='pdf'
            file="Jacqueline_Paul_Teacher_Resource.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              width={Math.min(width * 0.9, 600)}
              className="pdf-page" pageNumber={pageNumber}
            />
          </Document>
        </a>
        {paginate(numPages, pageNumber, setPageNumber)}
      </div>
      <br />
      <Contacts />
    </div>
  );
}
