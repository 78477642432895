import './footer.css'

import { Link } from "react-router-dom"


function Footer(props) {
  const pages = [
    { text: "Home", path: "/" },
    { text: "About", path: "/about" },
    { text: "Contact", path: "/contacts" },
    { text: "Books", path: "/books" },
  ]

  return (
    <div className={'footer ' + props.class_name_prop}>
      <ul className='footer-list'>
        {pages.map(page => {
          let class_name = 'navbar-link'
          return (
            <li key={page.path} className={page.class}>
              <Link className={class_name} to={page.path}>{page.text}</Link>
              {page.dropdown}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Footer
