import './news.css'

function News() {
    return (
        <div className="news-container">
            <h1 className="news-header">Latest News:</h1>
            <div className="news-item">
                <div className='news-text'>
                    <p>
                        Strong Nations Indigenous and Native American Books now carries NuhQuim, The Little Red Puppy.
                    </p>
                </div>
                <a className="news-item-img" target="_blank" href="https://www.strongnations.com/store/11487/nuhquim-the-little-red-puppy-a-star-and-bumblebee-book">
                    <img src='assets/images/strongnations.logo.png' />
                </a>
            </div>
            <div className="news-item">
                <div className='news-text'>
                    <p>
                        Check out the book <a href="backgrounder.pdf"> Backgrounder</a>!
                    </p>
                </div>
                <a href="backgrounder.pdf">
                    <img src='assets/images/backgrounder_thumbnail.png' />
                </a>
            </div>
        </div>
    )
}

export default News
