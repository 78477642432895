import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons'
import 'font-awesome/css/font-awesome.min.css';
import './contacts.css'
import { Component } from 'react';


class Contacts extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      name: '',
      subject: '',
      message: '',
      submit_message: 'submit'
    }
  }

  send_email = async (data) => {
    await fetch(process.env.REACT_APP_SERVER_URL + "/send-email", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: data
      }),
    })
      .then(res => {
        if(res.ok)
          return res.json()
        else
          return res.json().then(json => Promise.reject(json))

      })
      .then(res_json => {
        console.log(res_json)
      })
      .catch(e => {
        console.error(e.error)
      })
  }

  render() {
    return (
      <div>
        <div className={"contact-card " + "contacts-" + this.props.class_name_prop}>
          <h2>
            Contact Us!
          </h2>
          <form name='contact-form' className='form-container' onSubmit={e => {
            e.preventDefault()
            document.getElementsByName('contact-form')[0].reset()
            this.setState({submit_message: 'Thank You!'})
            this.send_email(this.state)
            }}>
              <div className='form-item-container email-area'>
                <label className='form-label'>Email</label>
                <br/>
                <input type='email' onChange={e => {
                  this.setState({email: e.target.value})
                  }}/>
                </div>
                <div className='form-item-container name-area'>
                  <label className='form-label'>Name</label>
                  <br/>
                  <input onChange={e => {
                  this.setState({name: e.target.value})
                  }}/>
                </div>
                <div className='form-item-container subject-area'>
                  <label className='form-label'>Subject</label>
                  <br/>
                  <input onChange={e => {
                  this.setState({subject: e.target.value})
                  }}/>
                </div>
                <div className='form-item-container message-area'>
                  <label className='form-label'>Message</label>
                  <br/>
                  <textarea className='form-textarea' onChange={e => {
                  this.setState({message: e.target.value})
                  }}/>
                </div>
                <input type='submit' value={this.state.submit_message} />
              </form>
              <h2>
                For more Star and Bumblebee Books:
              </h2>
              <div className='socials'>
                <a href="https://www.instagram.com/jacqueline_paul_author/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100087618244894">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href='https://www.tiktok.com/@jacquelinepaulauthor'>
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              </div>
            </div>
            <div className="contacts">
              <div className={"contact-card " + "contacts-" + this.props.class_name_prop}>
                <h2>
                  Jacqueline
                </h2>
                <p>
                  Keep up with the wrtiter:
                </p>
                <div className='socials'>
                  <a href="https://instagram.com/jacqueline_paul_author?igshid=Yzg5MTU1MDY=">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
              <div className={"contact-card " + "contacts-" + this.props.class_name_prop}>
                <h2>
                  Ȼosiniye
                </h2>
                <p>
                  More from the illustrator:
                </p>
                <div className='socials'>
                  <a href="https://www.instagram.com/artbycosiniye/">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
            </div>
          </div>
    )
  }
}

export default Contacts
