import './books.css'
import Buy_links from './buy-links.jsx'
import Carousal from './carousal.jsx'

function Books() {
  return (
    <div className="books-content">
      <div className='NuhQuim-card'>
        <a href="https://www.amazon.ca/Nuhquim-Little-Red-Puppy-Bumblebee-ebook/dp/B0B7ZZR3LJ/ref=mp_s_a_1_1?crid=2U7A6C3FGC6S8&keywords=nuhquim%2C+the+little+red+puppy&qid=1660939066&sprefix=nuhquim%2C+the+little+red+puppy%2Caps%2C189&sr=8-1">
          <img className='NuhQuim-card-img' src='assets/images/NuhquimTheLittleRedPuppy_Landing_img.png' />
        </a>
        <div className='NuhQuim-blerb'>
          <h1> NuhQuim, the Little Red Puppy </h1>
          <p>
            What would you do if your Mum brought home a new puppy?  Jump for joy?  Squeal with delight?  Hold that puppy oh so tight?  Find out how NuhQuim, the Little Red Puppy stirs things up for three young indigenous children!  Set on Vancouver Island in British Columbia, Canada, follow NuhQuim’s true story of how he touches three siblings with his fur, fang, and flare!  
            Now Available!
          </p>
          <p>
            The pets of the Family in the Field is growing.  Who is next to join NuhQuim and Sage-kitty?  Stay tuned for Meme’s book about an orange tabby kitten that a little girl put on her 5th birthday wish list.  “A movie, a candy necklace, a coca-cola float, a Ferris wheel ride, and a cat, is what she wrote. The Mum and the Dad exchanged a laugh, It’s not like she’s asking for a baby giraffe!”  How on earth did this little girl get her wishes??
            After Meme settled down in the long rancher house, along came Poofy Whoofy, he’s a fluffy little Havanese pup who traveled a great distance to be with the Family in the Field.  “A little cream puppy was on its way, flying in from Saskatchewan the very next day!”  Stay tuned for Poofy Whoofy’s book and his arrival into the furry family!
          </p>
        </div>
        <Buy_links />
        <Carousal className='books-carousal-container'/>
        <div className='NuhQuim-writeup'>
          <h2>Why did you write NuhQuim The Little Red Puppy?</h2>
          <p>
            I wrote this book to share a charming story. I used to keep journals of funny things my kids did, thinking
            I'd compile them one day. Give one to each as a memento to their young lives. Well, that's still a project
            I'm yet to do.
          </p>
          <p>
            Instead I recalled all the different pet shenanigans the kids had, as they were growing up on our little
            hobby farm. One particular story was when our daughter was 7 years old & how she was determined to
            buy herself a puppy after we said 'no' to her request.
            Four years ago, I jotted down that story in a rhyming verse kind of way. It was cute and it made me feel
            so happy. I then pictured writing a story of all the other pets and how they touched us. All true stories of
            course.
          </p>
          <p>
            Four months later I wrote the next book about our son, also requesting his own dog. Do you see a
            pattern?
          </p>
          <p>
            A year later, when covid shut-downs hit, this book, about our first dog, NuhQuim, was written. Suddenly I
            had time on my hands to write! I knew this would be the one to publish first, it was the foundation book.
            All other pets were acquired after him & I pictured him making guest appearances in subsequent pages
            of the series. And besides, NuhQuim was our first dog we barely knew what we were doing, much like
            me trying to publish a book or raising independent well rounded children! But adding a dog to the mix
            we did, the first of many, as we, two self-employed parents, raised three young siblings on a budding
            property.
          </p>
          <p>
            The more I thought about our family in a storyboard way I realized we had a unique set of attributes;
            being a blended indigineous family. Blended families are becoming more and more common and I
            thought there was a place for children all over the globe to see that represented in the images created
            by my youngest child for this children's book.
            Having strong ties to the Tsartlip nation, I thought the back of the book could tell the origin of their local
            language and a glossary and alphabet of SENĆOŦEN. A legacy language being revitalized today.
            And a book was born!
          </p>
          <div className='book-specifications-content'>
            <h3>Book Specifications</h3>

            <div className='book-specifications'>
              <p>
                Publication Date:  July 28, 2022
              </p>
              <p>
                Pages; 34
              </p>
              <p>
                Paperback: ISBN-13: 9780228870616
              </p>
              <p>
                Hardcover: ISBN-13: 9780228870623
              </p>
              <p>
                Ebook: ISBN-13: 9780228870630
              </p>
              <p>
                dimensions: 8.5” x 8.5”  (21.59 cm x 21.59 cm)
              </p>
            </div>
          </div>
        </div>
        <div className='customer-review-container'>
          <div className='customer-review-item'>
            <p>Elder Patti Underwood</p>
            <p>
              “It makes me so happy looking at this! This is what I have been teaching, that we need each other, young and old, to bring joy to the world. In this lifetime, we need it more than ever! There is too much sadness and anger. Looking at this book, is therapy, it makes me forget, even for a few minutes.  To read this children’s book, I just feel happy!”
            </p>
          </div>
          <div className='customer-review-item'>
            <img src='assets/images/Bernice_Smith.jpg'/>
            <p>Bernice Smith, Tsartlip First Nation</p>
            <p>
              “It’s incredible!  I have not read a book that has made me smile like this in a long time! This just made my day! I love the Salish touches in the pictures.”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Books
