// import About from './About.jsx'
import { Link } from 'react-router-dom'
import './landing_page.css'
import Contacts from './Contacts'
import Buy_links from './buy-links'

function LandingPage() {
  return (
    <div>
      <div className="landingpage">
        <div className="landingpage-content">
          <img src='assets/images/NuhquimTheLittleRedPuppy_Landing_img.png' />
          <div className="landingpage-cta">
            <h1 className="landingpage-header">Available Now!</h1>
            <Link to="/books" className="landingpage-button">Read More</Link>
          </div>
        </div>
      </div>
      <Buy_links />
      <div className='intro-container'>
        <h1 className='intro-text intro-header intro-highlight'>
          Hello and welcome to my author page!
        </h1>
        <img className="landingpage-bio-img" src="assets/jbioimage.jpeg" />
        <p className='intro-text intro-paragraph'>
          I’m so excited you found my book!  This has been a delightful family project which started with
          me reflecting on the many, many (many, many!!) pets we loved over the years, on our little hobby
          farm.
        </p>

        <p className='intro-text intro-paragraph'>
          At the height of our pet-acquiring we had: four dogs, five cats, two Bourkes parrotlets,
          two Angora goats, and a menagerie of chickens. I think we had a mouse or two in there as well!
          (Not recommended to get pet mice when you have cats, just a heads up there.)
        </p>

        <p className='intro-text intro-paragraph'>
          Our family was unique.  We lived by the backdrop of a ten acre forest, in a beautiful field, amongst 100 year old fruit trees.
          Our children and their father are First Nations from the Tsartlip territory on Vancouver Island and I am as pale and blonde as they come.
          We have a blended family and through the skillful illustrations of our youngest, I present to you a charming true story of our first dog!
        </p>

        <p className='intro-text intro-paragraph'>
          In the back of the book, with the approval of the SENĆOŦEN language council, I have a glossary and the SENĆOŦEN language alphabet.
          This book is destined to be translated fully into SENĆOŦEN to be used in their Immersion Survival School.
        </p>

        <p className='intro-text intro-paragraph'>
          My goal is to offer this book and subsequent books in this series, set in Tsartlip, to all Indigenous communities across
          Canada to translate fully into their hereditary languages.  If you are a First Nations community with a written language
          or an immersion school, that would like to utilize this book as a template to translate into your language, please contact me.
        </p>
        <p className='intro-text intro-paragraph'>
          I really hope you enjoy this tail (okay, I know its tale, but it IS about a puppy with a fluffy tail!!)  and I thank you
          greatly for taking the time to read it.
        </p>
        <p className='intro-text intro-paragraph'>
        </p>
        <p className='intro-text auther-tag intro-highlight'>
          Jacqueline Paul
        </p>
        <p className='intro-text intro-highlight'>
          Author
        </p>
      </div>
      <Contacts class_name_prop='green' />
    </div>
  )
}
          // <p>Check out Star and Bumblebee’s books</p>

export default LandingPage
