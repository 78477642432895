import './App.css';
import React, {
  useState,
  useEffect
} from 'react';

import {
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import Navbar from './navbar.jsx'
import NavbarBurger from './navbar_burger.jsx'
import Footer from './footer.jsx'
import LandingPage from './landing_page.jsx'
import Books from './books.jsx'
import About from './About.jsx'
import Contacts from './Contacts.jsx'
import News from './News.jsx'
import Carousal from './carousal';
import Educational from './educational.jsx'

function App() {
  let location = useLocation()

  let makelist = (pages, props) => {
    return pages.map(page => {
      let class_name = 'navbar-link-' + props.colour
      if(props.page === page.path)
        class_name = 'navbar-selected-' + props.colour

      return (<Link key={page.path} className={class_name} to={page.path}>{page.text}</Link>)
    })
  }

  let makeNavList = (props) => {

    let pages = [
      { text: "About", path: "/about" },
      { text: "News", path: "/news" },
      { text: "Contacts", path: "/contacts" },
      { text: "Books", path: "/books" },
      { text: "Educational Resources", path: "/educational" },
    ]

    if(props != undefined)
      return makelist(pages, props)
    else
      return ''
  }

  let makeFooterList = (props) => {
    let pages = [
      { text: "Home", path: "/" },
      { text: "About", path: "/about" },
      { text: "News", path: "/news" },
      { text: "Contacts", path: "/contacts" },
      { text: "Books", path: "/books" },
    ]

    if(props != undefined)
      return makelist(pages, props)
    else
      return ''
  }

  let [backgroundClassname, setBackgroundClassname] = useState('')
  let [navbar, setNavbar] = useState((<React.Fragment>
    <Navbar class_name_prop={'background-' + backgroundClassname} page={location.pathname} makeNavList={makeNavList} />
  </React.Fragment>))

  let resize = () => {
    let tempBackgroundClassname = ''
    switch(location.pathname) {
      case '/contacts':
        tempBackgroundClassname = 'purple'
        break;
      case '/news':
        tempBackgroundClassname = 'purple'
        break;
      case '/about':
        tempBackgroundClassname = 'purple'
        break;
      case '/educational':
        tempBackgroundClassname = 'green'
        break;
      default:
        tempBackgroundClassname = 'green'
        break;
    }

    setBackgroundClassname(tempBackgroundClassname)

    if(window.innerWidth > 500) {
      setNavbar((<React.Fragment>
        <Navbar colour={tempBackgroundClassname} class_name_prop={'navbar-' + tempBackgroundClassname} page={location.pathname} makeNavList={makeNavList} />
      </React.Fragment>))
    } else {
      setNavbar((<React.Fragment>
        <NavbarBurger colour={tempBackgroundClassname} class_name_prop={'navbar-' + tempBackgroundClassname} page={location.pathname} makeNavList={makeNavList} />
      </React.Fragment>))
    }
  }

  useEffect(resize , [location])

  return (
    <div className={'background-' + backgroundClassname}>
      {navbar}
      <div className='content'>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/books" element={<Books />} />
          <Route path="/contacts" element={<Contacts class_name_prop='purple'/>} />
          <Route path="/about" element={<About />} />
          <Route path="/news" element={<News />} />
          <Route path="/educational" element={<Educational />} />
          <Route path="/test" element={<Carousal />} />
        </Routes>
      </div>
      <Footer class_name_prop={'footer-' + backgroundClassname}/>
    </div>
  );
}
        // <Routes>
        //   <Route path="/" element={Route_Container(<LandingPage />, 'green')} />
        //   <Route path="/books" element={Route_Container(<Books />, 'green')} />
        //   <Route path="/contacts" element={Route_Container(<Contacts class_name_prop='purple'/>, 'purple')} />
        //   <Route path="/about" element={Route_Container(<About />, 'purple')} />
        //   <Route path="/news" element={Route_Container(<News />, 'green')} />
        //   <Route path="/test" element={Route_Container(<Carousal />, 'green')} />
        // </Routes>


export default App
