import { Link } from 'react-router-dom'
import './navbar.css'

function Navbar(props){
  return (
    <div className={props.class_name_prop}>
      <header className='navbar'>
        <nav className="navbar-content">
          <Link className='navbar-logo' to='/'>
            <img className='' src='assets/logo.png'/>
          </Link>
          <span className="navbar-list">
            {props.makeNavList(props)}
          </span>
        </nav>
      </header>
    </div>
  );
}

export default Navbar
