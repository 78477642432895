import './Carousal.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery, {RightNav} from 'react-image-gallery';

function Carousal() {
  let images = [
    { original: 'assets/dog_van.jpeg', thumbnail:'assets/dog_van.jpeg'},
    { original: 'assets/kids.jpeg', thumbnail:'assets/kids.jpeg'},
    { original: 'assets/sage.jpeg', thumbnail:'assets/sage.jpeg'},
    { original: 'assets/wings.jpeg', thumbnail:'assets/wings.jpeg'},
  ]

  return (
    <div className="carousal-container">
      <ImageGallery showThumbnails={false} items={images} />
    </div>
  )
}

export default Carousal
